.legend-icon-fonts {
  font-size: 1.5em;
}

.legend-icon-inner-text {
  color: black;
  font-size: small;
  display: inline-block;
  margin: 5px 5px 0 0;
  padding: 5px 10px;
}