
.each-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 500px;
    width: 100%;
}

.awssld__bar {
    display: none;
}