.usa-card{
  width: 100%;
}

.usa-card__footer {
  padding-bottom: .3rem;
  padding-top: .5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  text-align: center;
}

.usa-card:last-child{
  margin-bottom: .1rem;
}

