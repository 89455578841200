* {
    box-sizing: border-box;
  }
  
/* Create two equal columns that floats next to each other */
.column {
    float: left;
    width: 50%;
    padding: 10px;
    height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row {
    content: "";
    display: table;
    clear: both;
    padding-bottom: 200px;
}

.userManagementContainer{
    max-width: "100%";
}

.userManagementBody {
    content: "";
    display: table;
    clear: both;
    padding-bottom: 10px;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}
.userManagementRow {
    content: "";
    display: flex !important;
    clear: both;
    padding-bottom: 10px !important;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column {
        width: 100%;
    }
}

.checkbox {
    padding-top: 0.5em;
}

.registration-h2 {
    padding-top: 1em;
    padding-bottom: 0em;
}

.registration-p {
    padding-bottom: 0em;
}
.registration-p-text {
    padding-bottom: 0em;
    margin-bottom: 0em;
}

.registration-h3 {
    padding-top: 1.5em;
    padding-bottom: 0em;
}

.registration-h5 {
    padding-top: 1em;
    padding-bottom: 0em;
    font-weight: bold;
}

select:disabled{
    background-color: lightgray;
    background-image: none;
}

input:disabled {
    background-color: lightgray;
}

.link-button {
    background: none!important;
    border: none;
    padding: 0;
    padding-top: 1em;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
    padding-bottom: 2.5em;
}