
/* Create two equal columns that floats next to each other */
.aircraft-column {
    float: left;
    width: 30%;
    padding: 10px;
}

/* Clear floats after the columns */
.aircraft-row:after {
    content: "";
    display: table;
    padding-bottom: 10em;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .column {
        width: 100%;
    }
}
