.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  font-weight: bold;
}

.nav-tabs .nav-link{
  border-bottom: 1px solid #dee2e6 !important;
}

.nav-tabs .nav-link:hover{
  color: gray !important;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 64px;
}