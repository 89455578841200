.account-link {
    color: black;
    text-decoration: none;
}

.account-link:hover {
    color: #005ea2;
    text-decoration: underline;
}

.upload-button {
    width: 200px;
    background-color: #005EA2;
    text-align: center;
    color: white;
    border-radius: 4px;
}

.upload-button:hover {
    color: #005ea2;
    background-color: red;
    
}

.dash-button {
    width: 160px;
    text-align: center;
    color: blue;
    text-align: 'center';
    border-radius: 4px;
}

.dash-button:hover {
    color: white;
    background-color: #005ea2;
    
}

.account-link-div{
    float: right;
    position: absolute;
    bottom: 0px;
    right: 5rem;
}

@media (min-width:64em)
{
    .account-link-div{
    float: right;
    position: absolute;
    bottom: 0px;
    right: 0rem;
    }
}

/* .usa-nav__inner{
    
} */

.usa-header--extended, .usa-nav__primary{
    margin-left: auto !important
}


@media (min-width: 64em)
{
.usa-header--extended .usa-nav__primary_extended {
    
    margin-left: 0rem; 
}
}

@media (min-width: 64em){
    .usa-nav__inner {
        max-width: max-content !important; 
        width: fit-content !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: .75em !important;

    }
}

    @media (min-width: 64em)
{
    .usa-navbar {
        margin-left: 0 !important;
        margin-right: 0 !important;
        max-width: 100% !important;
    }
}
.nav-button{
    /* background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NCIgaGVpZ2h0PSIzOSI+PHBhdGggZmlsbD0iIzU2NUM2NSIgZD0iTTYzLjM2IDQuMDA0TDYwLjE1NS43NDFjLS40MjctLjQzNi0uOTE5LS42NTQtMS40NzUtLjY1NC0uNTU1IDAtMS4wNDcuMjE4LTEuNDc1LjY1NEwzMiAyNi40IDYuNzk4Ljc0QzYuMzcuMzA2IDUuODc4LjA4OCA1LjMyMy4wODhjLS41NTYgMC0xLjA0OC4yMTgtMS40NzYuNjU0TC42NDIgNC4wMDVDLjIxMyA0LjQ0IDAgNC45NDEgMCA1LjUwN2MwIC41NjUuMjE0IDEuMDY2LjY0MiAxLjUwMWwyOS44ODMgMzAuNDI3Yy40MjcuNDM1LjkyLjY1MiAxLjQ3NS42NTIuNTU2IDAgMS4wNDctLjIxNyAxLjQ3NC0uNjUyTDYzLjM2MSA3LjAwOGMuNDI3LS40MzUuNjM5LS45MzYuNjM5LTEuNTAxIDAtLjU2Ni0uMjEyLTEuMDY3LS42NC0xLjUwM3oiLz48L3N2Zz4=),linear-gradient(transparent,transparent); */
    background-repeat: no-repeat;
    background-size: .5rem;
    background-position: right 1rem top 53%;
}

.usa-nav__primary button:hover{
    background-color: lightgrey;
}

@media (min-width: 64em){

    .usa-nav__primary, .usa-accordion__button span {
        font-family: "Source Sans Pro Web" !important;
        color: "#565c65";
        font-weight: "bold";
        margin-right: 0;
        padding-right: 1rem;
    }
}