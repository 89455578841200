@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap');

* {
  font-family: 'Public Sans', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  text-align: left;
  /* margin-left: 1rem; */
  /* margin-right: 1rem; */
}