.tooltip-icon-fonts {
  font-size: 1.0em;
}

#indexlabel {
  color: red;
  position: absolute;
  font-size: 16px;
  z-index: 1;
  background-color: #AAA
}

/* Popup arrow */
.indexlabel::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

