.navbar {
    background-color: #142945;
    height: 120px;
    /* padding: 0.5rem calc((100vw - 1000px) / 2); */
}

#unauthNavCssOverride .navbar-brand{

    color: white ;
}

.unauthNavLink{
    color: white ;
}
.unauthNavLink:hover{
    color: #8994a2 ;
}


.unauthNavBar{
    background-color: #142945;
    height: 110px;
    
}

.unauthBrand {
    color: #ffffff;
    text-decoration: none;
    white-space: nowrap;
}
@media (min-width: 64em){
    #unauthNavBarOverride .signUp:hover{
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        background: #fff;
        color: #142945;
    }
    
    #unauthNavBarOverride .signUp{
        border: 2px solid white;
        border-color: #ffffff;
        border-radius: 4px;
        padding: 10px 22px;
        height: 48px;
        /* width: 10vw; */
        color: #ffffff;
        outline: none;
        cursor: pointer;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        -webkit-text-decoration: none;
        text-decoration: none;
    }
    

#unauthNavBarOverride .usa-nav__primary button {
    color: white ;
    text-decoration: none;
    padding: 0 1rem;
    /* height: 100%; */
    cursor: pointer;
    font-family: 'Public Sans', sans-serif;
    font-weight: normal;
    font-size: 1rem;
}



#unauthNavBarOverride .usa-nav__inner {
    max-width: none!important;
    width: none!important;
}

#unauthNavBarOverride .usa-nav__primary button:hover {
    color: #8994a2 ;
    text-decoration: underline;
    background-color: transparent !important;
}

#unauthNavBarOverride a{
    text-decoration: none;
    padding-right:0px;
}

#unauthNavBarOverride .usa-nav{
    border-top: none;
}


    #unauthNavBarOverride .signInDiv{
        position: absolute;
        top:0px;
        right:110px;
    }

    #unauthNavBarOverride .menuDiv{
        display: flex;
        align-items: baseline;
        width: 100vw;
        padding-top: 10px;
    }
}

@media (max-width: 63.99em) {
    #unauthNavBarOverride .usa-navbar {
        border-bottom: none;

        
    }
    .unauthNavBar{
        background-color: #142945;
        height: auto;
        padding-bottom:10px;
        
    }
    #unauthNavBarOverride .usa-menu-btn {
        background-color: transparent!important;
    }
}