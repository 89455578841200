
.dashboard-container {
  /* width: 45em; */
  margin-left: auto;
  margin-right: auto;
  
}

.dashboard-container-slider {
  /* width: 45em; */
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.dashboardRow {
  display: flex;
  flex-wrap: wrap;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x)/ -2);
  margin-left: calc(var(--bs-gutter-x)/ -2);
  padding-bottom: 5px !important;
  }

.card-container {
  width: 14em;
  margin-left: none;
  margin-right: auto;
}

.alert {
  text-align: left;
}

p {
  text-align: left;
}

.upload-button {
  background-color: #005EA2;
  border: none;
  border-radius: 4px;
  color: white;
  text-decoration-color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  width: 200px;
  margin-left: 50px;
}

.usa-card__footer {
  padding-bottom: .3rem;
  padding-top: .5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  text-align: center;
}

.usa-card:last-child{
  margin-bottom: .1rem;
}

.button-text {
  color: white;
}

.dashboard-row, dashboard-row row{
  display: flex !important;
  padding-bottom: 10px !important;
  /* background-color: rgb(246,246,246) !important; */

}
