.collapsed-container {
  margin-right: 50px;
  display: inline-block;
  vertical-align: bottom;
}

.collapsed-content {
  margin: 0px;
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.control-details {
  display: flex;
  flex-direction: row;
}

:focus {outline:0 !important;}