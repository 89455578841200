.rounded-rect {
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 50px -25px black;
  }

  
   
  .flex-center {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  }
   
  .flex-center.left {
  left: 0px;
  }
   
  .flex-center.right {
  right: 0px;
  }

  .flex-sidebar-contents {
  position: absolute;
  display: block;
  /* justify-content: center;
  align-items: center; */
  }

  .flex-sidebar-contents {
    position: absolute;
    display: block;
    /* justify-content: center;
    align-items: center; */
    }

  .flex-bottombar-contents {
    position: absolute;
    display: block;
    /* justify-content: center;
    align-items: center; */
    }
    
  .flex-sidebar-contents.left {
  left: .5%;
  }
    
  .flex-bottombar-contents.bottom {
  bottom: .5%;
  }
   
  .sidebar-content {
  position: absolute;
  display: block;
  width: 100%;
  height: calc(100% - 50px);
  /* padding-left: 3%; */
  padding-right: 3%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  color: gray;
  overflow-y: auto;
  scrollbar-gutter: stable;
  }

  .sidebar-over {
    position: absolute;
    width: 98%;
    height: calc(100% - 40px);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
    color: gray;
    }

    .sidebar-container {
      display:flex;
      width: 98%;
    height: 98%;
      position:absolute;
      align-items: baseline;
      }
  
   
  .sidebar-toggle {
  position: absolute;
  width: .7em;
  height: 1.3em;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  }
   
  .sidebar-toggle.left {
  right: -1.2em;
  }
   
  .sidebar-toggle.right {
  left: -1.5em;
  }
   
  .sidebar-toggle:hover {
  color: #0aa1cf;
  cursor: pointer;
  }
   
  .sidebar {
  transition: all 1s;
  z-index: 1;
  width: 60%;
  top: .5%;
  
  }

  .full-size {
    height: 100%
  }

  .with-bottom {
    height: 59%;
    }

  .side-bottom-shrink {
    height: 85%;
    }

  .bottombar {
  transition: all 1s;
  z-index: 1;
  width: 99%;
  height: 40%;
  margin-left: .5%;
  margin-right: .5%;
  }
     
   
  /*
  The sidebar styling has them "expanded" by default, we use CSS transforms to push them offscreen
  The toggleSidebar() function removes this class from the element in order to expand it.
  */
  .left.collapsed {
  transform: translateX(-100%);
  }

  .left.shrunk {
  transition: height 60%;
  }

     
    .flex-center {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    }
     
    .flex-center.bottom {
    bottom: 0px;
    }
     
  
    
      
    
     
     
    .bottombar-content {
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 32px;
    color: gray;
    }
  
    .bottombar-over {
      position: absolute;
      width: 100%;
      height: 98%;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 32px;
      color: gray;
      }
  
      
     
    .bottombar-toggle {
    position: absolute;
    bottom : 0;
    width: 1.3em;
    height: .7em;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    }
     
    .bottombar-toggle.bottom {
    top: -.9em;
    }
     
     
    .bottombar-toggle:hover {
    color: #0aa1cf;
    cursor: pointer;
    }
     
    
    /*
    The sidebar styling has them "expanded" by default, we use CSS transforms to push them offscreen
    The toggleSidebar() function removes this class from the element in order to expand it.
    */
    .bottom.collapsed {
    transform: translateY(100%);
    }


    .darkStyle {
      color: white !important;
      background: black !important;
    }
    
     
    