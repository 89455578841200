* {
    box-sizing: border-box;
  }
  
/* Create two equal columns that floats next to each other */
.column {
    float: left;
    width: 50%;
    padding: 10px;
    height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row {
    content: "";
    display: table;
    clear: both;
    padding-bottom: 200px;
}


.searchMyFlightsRow {
    content: "";
    display: flex !important;
    clear: both;
    padding-bottom: 10px;
}

.searchMyFlightsCol {
    max-width: 300px;
}

.searchInputText {
    margin-top: 0px;
    height: 28px;
    line-height: inherit;
}

.lightDate 
.react-datepicker-wrapper
.react-datepicker__input-container {
    width: 100%;
}

.lightDate .react-datepicker-wrapper
.react-datepicker__input-container input 
     {
        display: block;
        font-size: large;
        width: 100%;
        background-color: white;
        border-style: solid;
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.23);
        padding:8px;
    }
    
.lightDate .react-datepicker__input-container input:hover {
    border-color: black;
}


.darkDate 
.react-datepicker-wrapper
.react-datepicker__input-container {
    width: 100%;
}

.darkDate .react-datepicker-wrapper
.react-datepicker__input-container input 
     {
        display: block;
        font-size: large;
        width: 100%;
        background-color: black;
        border-style: solid;
        border-width: 1px;
        border-color: rgba(255, 255, 255, 0.23);
        padding:8px;
        color: white;
    }
    
.darkDate .react-datepicker__input-container input:hover {
    border-color: white;
}

.inputLabel {
    width: 300px;
    align-self: center;
}

.MuiOutlinedInput-notchedOutline legend {display:none}


