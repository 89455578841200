.lds-dual-ring{
    display:inline-block;
    width: 39px;
    height: 39px;
}
.lds-dual-ring:after{
    content: " ";
    display: block;
    width:30px;
    height: 30px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid rgb(221, 56, 56);
    border-color: rgb(32, 187, 96) transparent rgb(50, 30, 165) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg)
    }
    100% {
        transform: rotate(360deg)
    }
}