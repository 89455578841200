.flight-tag {
    display: flex;
    justify-content: center;
    padding: 1px 1.9rem;
    color: black;
    text-transform: none;
}

.stretch_grid-container {
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: none;
   
}

.stretch_grid-container_myflights {
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: none;
    /* overflow-x : scroll; */
    /* overflow-y : scroll; */
    /* max-height : 80vh; */
    /* height: 73vh; */
}

.grid-flight-bar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.grid-checkboxes {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
}


.embedContainerHeight {
    /* height: 1783px; */
}

.myFlights_header{
    margin-left: 1rem;
    margin-right: 1rem;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    outline: none;
}


